import React from "react";
import styled from "styled-components";

const PopOverContent = styled.div`
  padding: 5px;
  margin: 5px 0;
  display: flex;
  border-radius: 8px;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #363636;
  }
  &:hover > div {
    color: #4bf2cc;
  }

  &:hover > div > svg {
    color: #4bf2cc;
  }
`;

const PopOverContentName = styled.div`
  padding: 0 10px;
  margin: 0;
  width: 100%;
  color: ${(props) => (props.active === "true" ? "#4bf2cc" : "#fff")};
  font-family: "Pretendard";
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const WWWCsettingNameIcon = (props) => {
  const { handleWWWC, id, name } = props;

  return (
    <PopOverContent onClick={() => handleWWWC(id)}>
      <PopOverContentName>{name}</PopOverContentName>
    </PopOverContent>
  );
};

export default WWWCsettingNameIcon;
