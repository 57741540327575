import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import "./i18n/i18n";
import store from "./store/store";

const rootElement = document.getElementById("luca");
const Luca = ReactDOM.createRoot(rootElement);

Luca.render(
  <Provider store={store}>
    <App />
  </Provider>
);
