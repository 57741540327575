import React from "react";
import styled from "styled-components";
import { ControlOutlined } from "@ant-design/icons";

const Icon = styled(ControlOutlined)`
  color: #fff;
  font-size: 35px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    color: #4bf2cc;
  }
`;
const WWWCsettingIcon = () => {
  return (
    <React.Fragment>
      <Icon />
    </React.Fragment>
  );
};
export default WWWCsettingIcon;
