import AxiosConfig from "./axiosConfig";

const axiosInstance = AxiosConfig();

export const ConnectAI = async (params) => {
  try {
    const response = await axiosInstance.post("connectAI", params);
    console.log("connectAI response", response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    if (error.response && error.response.status === 500) {
      throw new Error("An error occurred.");
    }
  }
};

export const Patients_ConnectAI = async (params) => {
  try {
    const response = await axiosInstance.post(
      "connectAI/patientsConnectAI",
      params
    );
    console.log("patients connectAI response", response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    if (error.response && error.response.status === 500) {
      throw new Error("An error occurred.");
    }
  }
};
