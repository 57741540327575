var UserDetails = JSON.parse(localStorage.getItem("luca_userDetails"));

const initialState = {
  token: UserDetails ? UserDetails.token : null,
  userName: UserDetails ? UserDetails.userName : null,
};
const UserDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_DETAILS":
      return Object.assign({}, state, {
        ...state,
        token: action.token,
        userName: action.userName,
      });
    default:
      return state;
  }
};
export default UserDetailsReducer;
