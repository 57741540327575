import React from "react";
import LucaHeader from "../Header/LucaHeader";
import styled from "styled-components";
import Navigation from "../navigation.jsx/navigation";

const Content = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 1240px;
  display: flex;
`;
const ContentInner = styled.div`
  padding: 0 50px;
  margin: 0;
  width: 100%;
  display: flex;
`;
const Main = styled.main`
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
`;
const Layout = ({ children, user_seq, patient_seq }) => {
  return (
    <React.Fragment>
      <LucaHeader />
      <Content>
        <ContentInner>
          <Navigation user_seq={user_seq} patient_seq={patient_seq} />
          <Main>{children}</Main>
        </ContentInner>
      </Content>
    </React.Fragment>
  );
};
export default Layout;
