import React from 'react';
import styled from 'styled-components';
import { RotateRightOutlined } from '@ant-design/icons';

const PopOverContent = styled.div`
    padding: 5px;
    margin: 5px 0;
    display: flex;
    border-radius: 8px;
    align-items: center;
    &:hover{
        cursor: pointer;
        background-color:#363636;
    }
    &:hover > div {
        color: #4bf2cc;
    }
    
    &:hover > div > .anticon {
        color: #4bf2cc;
    }
`
const PopOverContentIcon = styled.div`
    padding: 50px;
    margin: 0;
    width:auto;
    display: flex;
    padding:5px;
`
const PopOverContentName = styled.div`
    padding:0 10px;
    margin: 0;
    width:100%;
    color: #fff;
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`

const Icon = styled(RotateRightOutlined)`
    color: #fff;
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const Rotate90IconBar = (props) => {
    const handlePortRotate = (props) => {
        props.handlePortRotate();
    };
    return(
        <PopOverContent onClick={() => handlePortRotate(props)}>
            <PopOverContentIcon>
                <Icon/>
            </PopOverContentIcon>
            <PopOverContentName>Rotate 90 degree</PopOverContentName>
        </PopOverContent>
    )
}
export default Rotate90IconBar;
