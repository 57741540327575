import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Modal, Button, message } from "antd";
import RefreshUpload from "./RefreshUpload";
import AxiosConfig from "../../api/axiosConfig.jsx";
import { Patients_ConnectAI } from "../../api/connectAI.jsx";

const axiosInstance = AxiosConfig();

const PatientModal = styled(Modal)`
  .ant-modal-content {
    background-color: #303030;
    color: #fefefe;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    background-color: #303030;
    color: #fefefe;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .ant-modal-close-x {
    color: #fefefe;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    font-family: "Pretendard";
    margin: 0;
  }

  input,
  label,
  button {
    font-family: "Pretendard";
  }
`;

const SaveBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.5rem;
  border: solid 0.1rem #4bf2cc;
  background-color: #303030;
  font-size: 1rem;
  font-weight: 500;
  color: #4bf2cc;

  .ant-btn-loading-icon {
    color: #4bf2cc;
  }

  &:hover {
    border-color: #4bf2cc !important;
    color: #4bf2cc !important;
    background: #303030 !important;
  }

  &:disabled {
    border-color: #6e6e6e !important;
    color: #6e6e6e !important;
  }
`;

const PatientModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 1.5rem 0;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  > p {
    width: 20%;
    font-size: 1rem;
    font-weight: 500;
  }

  > input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  > input[type="number"]::-webkit-outer-spin-button,
  > input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > input[type="text"],
  > input[type="number"] {
    width: 75%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: solid 0.1rem #969696;
    border-radius: 0.5rem;
    background-color: #747474;
    color: #fefefe;
  }
  > input[type="text"]::placeholder,
  > input[type="number"]::placeholder {
    color: #989898;
  }
  > input[type="text"]:focus,
  > input[type="number"]:focus,
  > input[type="text"]:focus-visible,
  > input[type="number"]:focus-visible {
    border: solid 0.1rem #4bf2cc;
    outline: 0;
  }
  > div {
    display: flex;
    width: 85%;

    > input[type="radio"] {
      display: none;
    }
    > input[type="radio"]:checked + label {
      border: solid 0.1rem #4bf2cc;
      color: #4bf2cc;
    }
    > label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: solid 0.1rem #969696;
      background-color: #747474;
      color: #fefefe;
      cursor: pointer;
    }
    > .male {
      border-radius: 0.5rem 0 0 0.5rem;
    }
    > .female {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }
`;

const RefreshModal = ({ open, setIsRefreshModalOpen, setIsRefresh }) => {
  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [saveFolders, setSaveFolders] = useState([]);
  const [path, setPath] = useState("");
  const refreshUploadRef = useRef(); // useRef를 사용하여 RefreshUpload 참조

  const handleUpload = (folders, path) => {
    setSaveFolders(folders);
    setPath(path);
    setSaveButtonEnabled(true);
  };

  const handleCancel = () => {
    if (refreshUploadRef.current) {
      // RefreshUpload 컴포넌트의 resetFolders 함수 호출
      refreshUploadRef.current.resetFolders();
    }
    setIsRefreshModalOpen(false);
    setSaveFolders([]);
  };

  const handleSave = async () => {
    if (saveFolders.length === 0) {
      message.destroy();
      message.error("No folders to save.");
      return;
    }
    console.log(saveFolders);
    console.log(saveFolders.length);

    setConfirmLoading(true);
    try {
      const updatedSaveFolders = saveFolders.map((folder) => {
        let { age, ...rest } = folder;

        age = age.replace(/[^\d]/g, "");

        while (age.startsWith("0")) {
          age = age.slice(1);
        }

        age = parseInt(age, 10);

        return { ...rest, age };
      });

      for (const folder of updatedSaveFolders) {
        const response = await axiosInstance.post("insertPatient/autoSave", {
          userPath: path,
          folderName: folder.folderName,
          patientName: folder.patientName,
          age: folder.age,
          gender: folder.gender,
          patientID: folder.patientID,
          section: folder.section,
          seriesInstanceUID: folder.seriesInstanceUID,
          studyInstanceUID: folder.studyInstanceUID,
          seriesDate: folder.seriesDate,
          studyDate: folder.studyDate,
          studyDescription: folder.studyDescription,
          user_id: userName,
        });
        console.log("response.data!!!!!!!!!!!!!!!!!!", response.data);
        if (response.data.section === "CT-CHEST") {
          const connectParams = {
            user_seq: response.data.user_seq,
            patient_seq: response.data.patient_seq,
            StudyInstanceUID: response.data.studyInstanceUID,
            SeriesInstanceUID: response.data.seriesInstanceUID,
          };
          Patients_ConnectAI(connectParams);
        }
      }

      setIsRefreshModalOpen(false);
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      if (refreshUploadRef.current) {
        // RefreshUpload 컴포넌트의 resetFolders 함수 호출
        refreshUploadRef.current.resetFolders();
      }
      setSaveFolders([]);
      setIsRefresh(true);
      setConfirmLoading(false); // 로딩 상태 해제
    }
  };

  return (
    <PatientModal
      title="Auto file Upload"
      onCancel={handleCancel}
      open={open}
      footer={null}
    >
      <PatientModalContent>
        <InputContainer>
          <InputBox>
            <RefreshUpload
              ref={refreshUploadRef} // RefreshUpload 컴포넌트를 참조
              onUpload={handleUpload}
              setSaveButtonEnabled={setSaveButtonEnabled}
            />
          </InputBox>
        </InputContainer>
        <div>
          <SaveBtn
            loading={confirmLoading}
            disabled={!isSaveButtonEnabled}
            onClick={handleSave}
          >
            Save
          </SaveBtn>
        </div>
      </PatientModalContent>
    </PatientModal>
  );
};

export default RefreshModal;
