import React from 'react';
import styled from 'styled-components';
import { LinkOutlined } from '@ant-design/icons';

const Icon = styled(LinkOutlined)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const SyncIcon = (props) => {
    
    return(
        <React.Fragment>
                <Icon active = {props.isActive.toString()}/>
        </React.Fragment>
    )
}
export default SyncIcon;
