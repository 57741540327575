import axios from "axios";
import store from "../store/store";
import { ApiLoadingStateAction } from "../store/action";
const { REACT_APP_API_URL } = process.env;

const AxiosConfig = () => {
  const instance = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    function (config) {
      var drugLink_userDetails = JSON.parse(
        localStorage.getItem("luca_userDetails")
      );

      if (drugLink_userDetails) {
        config.headers["Authorization"] = drugLink_userDetails.token;
      }
      store.dispatch(ApiLoadingStateAction(true));

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      store.dispatch(ApiLoadingStateAction(false));
      return response;
    },
    function (error) {
      store.dispatch(ApiLoadingStateAction(false));
      return Promise.reject(error);
    }
  );

  return instance;
};

export default AxiosConfig;
