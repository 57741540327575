import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Select, ConfigProvider } from "antd";
import { SearchParamsAction } from "../store/action";
import { SearchPatient } from "../api/patientApi";

const { Option } = Select;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 0.5rem;

  > h1 {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  width: 7rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.45rem 0;
  border-radius: 0.5rem;
  background-color: #4b4b51;

  @media (max-width: 1700px) {
    width: 6rem;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    background-color: #4b4b51 !important;
    color: #fff !important;
    border: none !important;
    box-shadow: none !important;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
  .ant-select-arrow {
    color: #fff !important;
  }
`;
const SearchSection = ({ sectionSearchResult, isFiltersCleared }) => {
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const currentSearchParams = useSelector((state) => state.SearchParamsReducer);

  useEffect(() => {
    if (isFiltersCleared) {
      setStatus(""); // 필터가 초기화되면 input 값을 초기화
    }
  }, [isFiltersCleared]);

  const handleStatusChange = async (value) => {
    setStatus(value);

    const updatedValue = value || "*";

    dispatch(
      SearchParamsAction(
        currentSearchParams.s_patientID,
        currentSearchParams.s_date,
        currentSearchParams.s_status,
        currentSearchParams.s_modality,
        updatedValue
      )
    );

    const updatedParams = { ...currentSearchParams, s_section: updatedValue };

    // 업데이트된 스토어 상태를 사용하여 API 호출 파라미터를 준비합니다.
    const params = {
      PatientID: updatedParams.s_patientID,
      Modality: updatedParams.s_modality,
      BodyPartExamined: updatedParams.s_section,
    };
    console.log("Search API Params:", params);

    try {
      const response = await SearchPatient(params);
      console.log(response.data);
      sectionSearchResult(response.data);
    } catch (error) {
      console.error("API Call Failed:", error);
    }
  };

  return (
    <Container>
      <h1>Section</h1>
      <SearchContainer>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                color: "#fff",
                optionSelectedBg: "#39393e",
                optionHoverBg: "#39393e",
                optionSelectedColor: "#fff",
              },
            },
          }}
        >
          <StyledSelect
            value={status}
            onChange={handleStatusChange}
            dropdownStyle={{ backgroundColor: "#4b4b51" }}
          >
            <Option style={{ color: "#fff" }} value="*">
              None
            </Option>
            <Option style={{ color: "#fff" }} value="CHEST">
              Chest
            </Option>
            <Option style={{ color: "#fff" }} value="BREAST">
              Breast
            </Option>
            <Option style={{ color: "#fff" }} value="ABDOMEN">
              Abdomen
            </Option>
          </StyledSelect>
        </ConfigProvider>
      </SearchContainer>
    </Container>
  );
};

export default SearchSection;
