import React from 'react';
import styled from 'styled-components';
import { HistoryOutlined } from '@ant-design/icons';

const Icon = styled(HistoryOutlined)`
    color: #fff;
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const ResetIcon = () => {
    return(
        <React.Fragment>
                <Icon/>
        </React.Fragment>
    )
}
export default ResetIcon;
