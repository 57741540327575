import React from 'react';
import styled from 'styled-components';
import { RxAngle } from "react-icons/rx";


const PopOverContent = styled.div`
    padding: 5px;
    margin: 5px 0;
    display: flex;
    border-radius: 8px;
    align-items: center;
    &:hover{
        cursor: pointer;
        background-color:#363636;
    }
    &:hover > div {
        color: #4bf2cc;
    }
    
    &:hover > div > svg {
        color: #4bf2cc;
    }
`
const PopOverContentIcon = styled.div`
    padding: 50px;
    margin: 0;
    width:auto;
    display: flex;
    padding:5px;
`
const PopOverContentName = styled.div`
    padding:0 10px;
    margin: 0;
    width:100%;
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`

const Icon = styled(RxAngle)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:35px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const ColorIcon = (props) => {
    const handleColor = (props) => {
        props.handleColor(props.id); 
    }
    
    return(
        <PopOverContent onClick ={() => handleColor(props)}>
            {/* <PopOverContentIcon>
                <Icon active = {isActive.toString()}/>
            </PopOverContentIcon> */}
            <PopOverContentName>{props.name}</PopOverContentName>
        </PopOverContent>
    )
}
export default ColorIcon;
