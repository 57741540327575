import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";

const Aside = styled.aside`
  padding: 0;
  margin: 0;
  width: auto;
  display: flex;
`;
const Nav = styled.nav`
  padding: 0;
  margin: 0;
  display: flex;
`;
const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`;
const Li = styled(NavLink)`
  padding: 0;
  margin: 0;
  width: 50px;
  height: 250px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #363636;
  text-decoration: none;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 100% 100%, 0 80%);
  &.active {
    background-color: #28282c;
  }
`;
const LinkText = styled.div`
  font-family: "Pretendard";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fefefe;
  text-decoration: none;
  white-space: nowrap;
  transform: rotate(-90deg);
`;
const Navigation = ({ user_seq, patient_seq }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Aside>
      <Nav>
        {currentPath === "/PatientList" ? (
          <Ul>
            <Li exact="true" to="/PatientList" activeclassname="active">
              <LinkText>Patient List</LinkText>
            </Li>
          </Ul>
        ) : currentPath === `/Viewer/${user_seq}/${patient_seq}` ? (
          <Ul>
            <Li exact="true" to="/PatientList" activeclassname="active">
              <LinkText>Patient List</LinkText>
            </Li>
            <Li
              exact="true"
              to={`/Viewer/${user_seq}/${patient_seq}`}
              activeclassname="active"
            >
              <LinkText>Viewer</LinkText>
            </Li>
            {/* <Li exact="true" to="/summary" activeclassname="active">
              <LinkText>Summary</LinkText>
            </Li> */}
          </Ul>
        ) : (
          <Ul>
            <Li exact="true" to="/PatientList" activeclassname="active">
              <LinkText>Patient List</LinkText>
            </Li>
            <Li
              exact="true"
              to={`/Viewer/${user_seq}/${patient_seq}`}
              activeclassname="active"
            >
              <LinkText>Viewer</LinkText>
            </Li>
            <Li
              exact="true"
              to={`/Summary/${user_seq}/${patient_seq}`}
              activeclassname="active"
            >
              <LinkText>Summary</LinkText>
            </Li>
          </Ul>
        )}
      </Nav>
    </Aside>
  );
};
export default Navigation;
