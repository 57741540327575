import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DoughnutChart from "./tools/DoughnutChart";
import SliderChart from "./tools/SliderChart";
import { SummaryDataAction } from "../../store/action";
import { openDB, addOrUpdateEntry } from "../indexedDB/idbUtils.js";
import { ConnectAI } from "../../api/connectAI.jsx";
import { LoadingOutlined } from "@ant-design/icons";

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;
const RiskContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #212121;
  padding: 12px;
`;

const NoneRiskContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #212121;
  padding: 12px;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  gap: 0.5rem;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  border-radius: 6px;
  border: solid 1px #747474;
  cursor: pointer;
  color: #fff;
  width: 30%;

  &:hover {
    border: solid 1px #4bf2cc;
    color: #4bf2cc;
  }
`;

const DisabledButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  border-radius: 6px;
  border: solid 1px #747474;
  cursor: not-allowed;
  color: #fff;
  width: 30%;
`;

const ButtonText = styled.div`
  font-family: Pretendard;
  font-size: 16.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;
const TitleContainer = styled.div`
  padding: 5px;
  margin: 0;
  width: auto;
  height: auto;
  display: flex;
`;
const Title = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4bf2cc;
`;
const ChartBar = styled.div`
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  display: flex;
`;

const BtnBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 12px 0 12px;
  margin: 0;
  height: auto;
  display: flex;
  background-color: #212121;

  > div {
    display: flex;
    width: 100%;
  }
`;

const PatientInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin: 0;
  height: auto;
  display: flex;
  background-color: #212121;

  > div {
    display: flex;
    width: 100%;

    > h1 {
      margin: 0;
      padding: 5px;
      font-family: Pretendard;
      font-size: 20px;
      font-weight: 600;
      color: #4bf2cc;
    }
  }
`;
const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding: 5px;
`;
const InfoRow = styled.div`
  display: flex;
  padding: 5px 0;
`;
const InfoLabel = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4bf2cc;
  width: 30%;
`;
const InfoValue = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fefefe;
  width: 70%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NoneResult = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
`;

const Result = (props) => {
  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const navigate = useNavigate();
  const [riskValue, setRiskValue] = useState(props.risk);
  const [thresholdValue, setThresholdValue] = useState(props.threshold);
  const [loading, setLoading] = useState(false);

  const intervalRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.risk) {
      const resultAI_db = parseFloat((props.risk * 100).toFixed(2));
      setRiskValue(resultAI_db);
    } else {
      setRiskValue(null);
    }
  }, [props.risk]);

  //console.log("props", props);

  useEffect(() => {
    // progressNum이 0이 아니거나 null이 아닐 때만 실행
    if (props.resultAI === "progress") {
      setLoading(true);
      intervalRef.current = setInterval(() => {
        props.fetchData();
      }, 8000); // 8초마다 fetchData 호출
    }

    console.log("props", props);

    if (props.resultAI !== "progress") {
      setLoading(false);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // 컴포넌트 언마운트 시 setInterval 중단
      }
    };
  }, [props.resultAI]);

  const handleConnectionAI = () => {
    if (props.section === "CT-CHEST") {
      console.log("AI 연결 시작");
      setLoading(true);

      const User_seq = props.user_seq;
      const Patient_seq = props.patient_seq;

      const StudyInstanceUID = props.studyInstanceUID;
      const SeriesInstanceUID = props.seriesInstanceUID;

      const params = {
        user_seq: User_seq,
        patient_seq: Patient_seq,
        StudyInstanceUID: StudyInstanceUID,
        SeriesInstanceUID: SeriesInstanceUID,
      };

      ConnectAI(params)
        .then((response) => {
          console.log("AI 연결 성공", response);

          setLoading(false); // 로딩 완료
          props.setConnection(true);
          props.fetchData();
        })
        .catch((error) => {
          console.error("AI 연결 실패", error);
          setLoading(false); // 에러 시에도 로딩 상태 해제
        });
    } else {
      console.log("AI 연결 못함");
    }
  };

  const handleSliderChange = async (value) => {
    setThresholdValue(value);

    try {
      const db = await openDB("thresholdDB", "thresholds");
      await addOrUpdateEntry(db, "thresholds", {
        id: `${userName}_thresholdValue`,
        value: value,
      });
    } catch (error) {
      console.error("Failed to update IndexedDB", error);
    }
  };

  const handleNavigation = (path) => {
    dispatch(SummaryDataAction(thresholdValue));

    navigate(path);
  };

  return riskValue ? (
    <React.Fragment>
      <ResultContainer>
        <BtnBox>
          <div>
            <ButtonContainer>
              <DisabledButton>
                <ButtonText>Analysis</ButtonText>
              </DisabledButton>
              <Button
                onClick={() =>
                  handleNavigation(
                    `/Summary/${props.user_seq}/${props.patient_seq}`
                  )
                }
              >
                <ButtonText>Summary</ButtonText>
              </Button>
              <Button onClick={() => handleNavigation("/PatientList")}>
                <ButtonText>Exit</ButtonText>
              </Button>
            </ButtonContainer>
          </div>
        </BtnBox>
        <PatientInformation>
          <div>
            <h1>Patient Information</h1>
          </div>
          <div>
            <InfoContent>
              <InfoRow>
                <InfoLabel>Name</InfoLabel>
                <InfoValue>{props.patientName}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Sex</InfoLabel>
                <InfoValue>{props.patientSex}</InfoValue>
              </InfoRow>
            </InfoContent>
            <InfoContent>
              <InfoRow>
                <InfoLabel>ID</InfoLabel>
                <InfoValue>{props.patientID}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Age</InfoLabel>
                <InfoValue>{props.patientAge}</InfoValue>
              </InfoRow>
            </InfoContent>
          </div>
        </PatientInformation>
        <RiskContainer>
          <TitleContainer>
            <Title>Risk for Lung Cancer within 5 years</Title>
          </TitleContainer>
          <ChartBar>
            <DoughnutChart risk={riskValue} threshold={thresholdValue} />
            <SliderChart
              risk={riskValue}
              threshold={thresholdValue}
              handleSliderChange={handleSliderChange}
            />
          </ChartBar>
        </RiskContainer>
      </ResultContainer>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <ResultContainer>
        <BtnBox>
          <div>
            <ButtonContainer>
              {loading ? (
                <DisabledButton>
                  <ButtonText>Analysis</ButtonText>
                </DisabledButton>
              ) : (
                <Button onClick={handleConnectionAI}>
                  <ButtonText>Analysis</ButtonText>
                </Button>
              )}
              <DisabledButton>
                <ButtonText>Summary</ButtonText>
              </DisabledButton>
              <Button onClick={() => handleNavigation("/PatientList")}>
                <ButtonText>Exit</ButtonText>
              </Button>
            </ButtonContainer>
          </div>
        </BtnBox>
        <PatientInformation>
          <div>
            <h1>Patient Information</h1>
          </div>
          <div>
            <InfoContent>
              <InfoRow>
                <InfoLabel>Name</InfoLabel>
                <InfoValue>{props.patientName}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Sex</InfoLabel>
                <InfoValue>{props.patientSex}</InfoValue>
              </InfoRow>
            </InfoContent>
            <InfoContent>
              <InfoRow>
                <InfoLabel>ID</InfoLabel>
                <InfoValue>{props.patientID}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Age</InfoLabel>
                <InfoValue>{props.patientAge}</InfoValue>
              </InfoRow>
            </InfoContent>
          </div>
        </PatientInformation>
        <NoneRiskContainer>
          <NoneResult>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 30, color: "white" }} spin />
            ) : (
              <Button onClick={handleConnectionAI}>
                <ButtonText>Analysis</ButtonText>
              </Button>
            )}
          </NoneResult>
        </NoneRiskContainer>
      </ResultContainer>
    </React.Fragment>
  );
};

export default Result;
