import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Select, ConfigProvider } from "antd";
import { SearchParamsAction } from "../store/action";

const { Option } = Select;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 0.5rem;

  > h1 {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  width: 7rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.45rem 0;
  border-radius: 0.5rem;
  background-color: #4b4b51;

  @media (max-width: 1700px) {
    width: 6rem;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    background-color: #4b4b51 !important;
    color: #fff !important;
    border: none !important;
    box-shadow: none !important;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
  .ant-select-arrow {
    color: #fff !important;
  }
`;
const SearchStatus = ({ isFiltersCleared }) => {
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const currentSearchParams = useSelector((state) => state.SearchParamsReducer);

  useEffect(() => {
    if (isFiltersCleared) {
      setStatus(""); // 필터가 초기화되면 input 값을 초기화
    }
  }, [isFiltersCleared]);

  const handleStatusChange = (value) => {
    setStatus(value);
    console.log(value);

    const updatedValue = value || null;

    dispatch(
      SearchParamsAction(
        currentSearchParams.s_patientID,
        currentSearchParams.s_date,
        updatedValue,
        currentSearchParams.s_modality,
        currentSearchParams.s_section
      )
    );
  };

  return (
    <Container>
      <h1>Status</h1>
      <SearchContainer>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                color: "#fff",
                optionSelectedBg: "#39393e",
                optionHoverBg: "#39393e",
                optionSelectedColor: "#fff",
              },
            },
          }}
        >
          <StyledSelect
            value={status}
            onChange={handleStatusChange}
            dropdownStyle={{ backgroundColor: "#4b4b51" }}
          >
            <Option style={{ color: "#fff" }} value="null">
              None
            </Option>
            <Option style={{ color: "#fff" }} value="complete">
              Complete
            </Option>
            <Option style={{ color: "#fff" }} value="not">
              Analysis
            </Option>
            <Option style={{ color: "#fff" }} value="error">
              Status Error
            </Option>
          </StyledSelect>
        </ConfigProvider>
      </SearchContainer>
    </Container>
  );
};

export default SearchStatus;
