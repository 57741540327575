import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { List, message } from "antd";
import styled from "styled-components";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  ReloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { loadFromDB, openPathDB, savePathToDB } from "../indexedDB/idbUtils.js";
import AxiosConfig from "../../api/axiosConfig.jsx";

const axiosInstance = AxiosConfig();

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`;

const FileList = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 23rem;
  overflow-y: auto;

  p {
    margin: 0.2rem 0;
    font-family: "Pretendard";
    font-size: 14px;
    color: #fff;
  }
`;

const InputBox = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  padding-bottom: 2rem;
  border-bottom: solid 0.1rem #747474;

  > p {
    width: 17%;
    font-size: 1rem;
    font-weight: 500;
  }

  > input[type="text"] {
    width: 45%;
    padding: 7px 10px;
    font-size: 1rem;
    border: solid 0.1rem #969696;
    border-radius: 0.5rem;
    background-color: #747474;
    color: #fefefe;
  }
  > input[type="text"]::placeholder {
    color: #989898;
  }
  > input[type="text"]:focus,
  > input[type="text"]:focus-visible {
    border: solid 0.1rem #4bf2cc;
    outline: 0;
  }
  > input[type="text"]:disabled {
    border: solid 0.1rem #747474;
    color: #bcbcbc;
  }
`;

const FileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ModifyBtn = styled.button`
  display: flex;
  width: 89.73px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  padding: 7px 10px;
  cursor: pointer;
  gap: 10px;

  > p {
    margin: 0;
    font-size: 13.5px;
    font-weight: 400;
    color: #0d0d0d;
    transform: translateY(-1px);
  }
`;

const ChkBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  padding: 7px 10px;
  cursor: pointer;
  gap: 10px;
  border: 1px solid #fff;

  > p {
    margin: 0;
    font-size: 13.5px;
    font-weight: 400;
    color: #0d0d0d;
    transform: translateY(-1px);
  }
`;

const CustomList = styled(List)`
  .ant-empty-normal .ant-empty-description {
    color: #fff;
  }
`;

const FolderStateContainer = styled.div`
  display: flex;
  gap: 10px;

  > h1 {
    margin: 0;
    font-size: 13.5px;
    font-weight: 400;
  }
`;

const RefreshUpload = forwardRef(({ onUpload, setSaveButtonEnabled }, ref) => {
  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const [path, setPath] = useState("C:/LucaCast");
  const [folders, setFolders] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    resetFolders: () => setFolders([]),
  }));

  useEffect(() => {
    const fetchPathFromDB = async () => {
      try {
        const db = await openPathDB("pathDB", "pathStore");
        const storedPath = await loadFromDB(db, "pathStore", userName);
        if (storedPath) {
          setPath(storedPath.path);
        }
      } catch (error) {
        console.error("Error fetching path from DB:", error);
      }
    };

    fetchPathFromDB();
  }, [userName]);

  useEffect(() => {
    const isSaveDisabled = isEditing || !path.trim();
    setSaveButtonEnabled(!isSaveDisabled);
  }, [path, folders, isEditing, setSaveButtonEnabled]);

  const handleModify = async () => {
    if (!path || path.trim() === "") {
      message.destroy();
      message.error("Please enter a valid path");
      return;
    }

    try {
      const db = await openPathDB("pathDB", "pathStore");
      await savePathToDB(db, "pathStore", { id: userName, path: path });
      setFolders([]);
      message.destroy();
      message.success("Path modified successfully");
    } catch (error) {
      console.error("Error saving path:", error);
      message.destroy();
      message.error("Failed to modify path");
    }
    setIsEditing(false);
  };

  const loadFolders = async () => {
    try {
      const response = await axiosInstance.post("insertPatient/auto", {
        userPath: path,
      });
      const folderNames = response.data.map((item) => ({
        folder: item.folder,
        hasNonDcmFiles: item.files.some((file) => !file.endsWith(".dcm")),
        checked: false,
      }));
      setFolders(folderNames);
      const dcmFolders = folderNames
        .filter((item) => !item.hasNonDcmFiles)
        .map((item) => item.folder);
      return { folderNames, dcmFolders };
    } catch (error) {
      console.error("Error loading folders:", error);
      return { folderNames: [], dcmFolders: [] };
    }
  };

  const handleChk = async () => {
    setLoading(true);
    const { folderNames, dcmFolders } = await loadFolders();

    if (folderNames.length === 0) {
      setFolders([]);
      message.destroy();
      message.error("No DICOM files found in the specified path");
      setLoading(false);
      return;
    }

    let alreadySaveFolders = [];

    try {
      const response_select = await axiosInstance.post(
        "insertPatient/autoFolderSelect",
        {
          user_id: userName,
          userPath: path,
        }
      );
      console.log("autoFolderSelect", response_select);
      alreadySaveFolders = response_select.data.map((item) => item.folderName);
    } catch (error) {
      console.error("Error checking folders:", error);
    }

    try {
      const response = await axiosInstance.post("insertPatient/autoChk", {
        userPath: path,
        userFiles: dcmFolders,
      });
      console.log("autoChk", response);

      const checkedFolders = response.data
        .flat()
        .map((item) => item.folderName);

      const uniqueCheckedFolders = checkedFolders.filter(
        (folderName) => !alreadySaveFolders.includes(folderName)
      );

      let updatedFolders = folderNames.map((folder) => ({
        ...folder,
        checked: uniqueCheckedFolders.includes(folder.folder),
        alreadySaved: alreadySaveFolders.includes(folder.folder),
      }));

      updatedFolders = updatedFolders.sort((a, b) => {
        if (a.checked && !b.checked) return -1;
        if (!a.checked && b.checked) return 1;
        if (a.alreadySaved && !b.alreadySaved) return -1;
        if (!a.alreadySaved && b.alreadySaved) return 1;
        return a.folder.localeCompare(b.folder);
      });

      setFolders(updatedFolders);

      const saveFolders = response.data
        .flat()
        .filter((item) => !alreadySaveFolders.includes(item.folderName));

      onUpload(saveFolders, path);
    } catch (error) {
      console.error("Error saving folders:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleEditing = () => {
    if (isEditing) {
      handleModify();
    } else {
      setIsEditing(true);
    }
  };

  return (
    <FileUploadContainer>
      <InputBox>
        <p>Path</p>
        <input
          type="text"
          placeholder="file path"
          value={path}
          onChange={(e) => setPath(e.target.value)}
          disabled={!isEditing}
        />
        <ModifyBtn onClick={toggleEditing}>
          <p>{isEditing ? "Save" : "Modify"}</p>
        </ModifyBtn>
      </InputBox>
      <FileListContainer>
        <ListBtnContainer>
          <ChkBtn onClick={handleChk}>
            <p>Refresh</p>
            <ReloadOutlined />
          </ChkBtn>
        </ListBtnContainer>
        <FileList>
          <CustomList
            style={{ padding: "0 10px" }}
            dataSource={folders}
            renderItem={(item) => (
              <List.Item>
                <p>{item.folder}</p>
                {loading ? (
                  <LoadingOutlined style={{ color: "#46d7b6" }} />
                ) : item.checked ? (
                  <FolderStateContainer>
                    <h1 style={{ color: "#00dd00" }}>File upload available</h1>
                    <CheckCircleFilled style={{ color: "#00dd00" }} />
                  </FolderStateContainer>
                ) : item.alreadySaved ? (
                  <FolderStateContainer>
                    <h1 style={{ color: "#808080" }}>
                      Previously uploaded file
                    </h1>
                    <CheckCircleFilled style={{ color: "#808080" }} />
                  </FolderStateContainer>
                ) : (
                  <FolderStateContainer>
                    <h1 style={{ color: "#ff4040" }}>Unable to save</h1>
                    <CloseCircleFilled style={{ color: "#ff4040" }} />
                  </FolderStateContainer>
                )}
              </List.Item>
            )}
          />
        </FileList>
      </FileListContainer>
    </FileUploadContainer>
  );
});

export default RefreshUpload;
