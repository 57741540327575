import AxiosConfig from "./axiosConfig";

const axiosInstance = AxiosConfig();

export const UserLogin = async (params) => {
  try {
    const response = await axiosInstance.post("user", params);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    throw new Error("An error occurred during login.");
  }
};

export const UserLogout = async (params) => {
  try {
    const response = await axiosInstance.post("user/logout", params);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    throw new Error("An error occurred during logout.");
  }
};

export const fetchCheckSession = async () => {
  try {
    const response = await axiosInstance.post("checkSession");
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    throw new Error("An error occurred during chkSession.");
  }
};

export const UserChkToken = async (params) => {
  try {
    const response = await axiosInstance.post("user/chkToken", params);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    throw new Error("An error occurred during chkToken.");
  }
};
