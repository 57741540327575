import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Layout from "../modules/layout/Layout";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DicomViewer from "../modules/dicom/DicomViewer";
import { UserChkToken } from "../api/userApi";
import store from "../store/store";
import { UserDetailsAction } from "../store/action";

const MainInner = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: calc(100vh - 63px);
  display: flex;
`;

const Viewer = () => {
  const { user_seq, patient_seq } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const userName = useSelector((state) => state.UserDetailsReducer.userName);

  //중복 로그인 방지 처리
  const checkDuplicateLogin = async () => {
    if (!userName) return; // userName이 없으면 함수 종료
    const params = { user_id: userName };
    if (location.pathname !== "/") {
      const localStorageTokenString = localStorage.getItem("luca_userDetails");
      if (!localStorageTokenString) {
        console.error("localStorageToken is null");
        return;
      }
      const localStorageToken = JSON.parse(localStorageTokenString);
      try {
        const serverToken = await UserChkToken(params);
        if (localStorageToken.token !== serverToken[0].token) {
          store.dispatch(UserDetailsAction(null));
          localStorage.removeItem("luca_userDetails");
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error during checkDuplicateLogin:", error);
      }
    }
  };

  checkDuplicateLogin();

  //뷰어 페이지 다이콤 파일 렌더링 버그 일단 새로고침으로 막아둠
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (!queryParams.get("reloaded")) {
      queryParams.set("reloaded", "true");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
      window.location.reload();
    }
  }, [location, navigate]);

  return (
    <Layout user_seq={user_seq} patient_seq={patient_seq}>
      <MainInner>
        <DndProvider backend={HTML5Backend}>
          <DicomViewer user_seq={user_seq} patient_seq={patient_seq} />
        </DndProvider>
      </MainInner>
    </Layout>
  );
};

export default Viewer;
