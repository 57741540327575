import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Modal, Button, message } from "antd";
import FileUpload from "./FileUpload";
import * as dicomParser from "dicom-parser";
import { InsertPatient } from "../../api/insertPatientApi";
import { Patients_ConnectAI } from "../../api/connectAI";

const { REACT_APP_ORTHANC_URL2 } = process.env;

const PatientModal = styled(Modal)`
  .ant-modal-content {
    background-color: #303030;
    color: #fefefe;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    background-color: #303030;
    color: #fefefe;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .ant-modal-close-x {
    color: #fefefe;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    font-family: "Pretendard";
    margin: 0;
  }

  input,
  label,
  button {
    font-family: "Pretendard";
  }
`;

const SaveBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.5rem;
  border: solid 0.1rem #4bf2cc;
  background-color: #303030;
  font-size: 1rem;
  font-weight: 500;
  color: #4bf2cc;

  .ant-btn-loading-icon {
    color: #4bf2cc;
  }

  &:hover {
    border-color: #4bf2cc !important;
    color: #4bf2cc !important;
    background: #303030 !important;
  }

  &:disabled {
    border-color: #6e6e6e !important;
    color: #6e6e6e !important;
  }
`;

const PatientModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 1rem;
  padding: 1.5rem 0;
  border-bottom: solid 0.1rem #747474;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;

  > p {
    width: 20%;
    font-size: 1rem;
    font-weight: 500;
  }

  > input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  > input[type="number"]::-webkit-outer-spin-button,
  > input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > input[type="text"],
  > input[type="number"] {
    width: 75%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: solid 0.1rem #969696;
    border-radius: 0.5rem;
    background-color: #747474;
    color: #fefefe;
  }
  > input[type="text"]::placeholder,
  > input[type="number"]::placeholder {
    color: #989898;
  }
  > input[type="text"]:focus,
  > input[type="number"]:focus,
  > input[type="text"]:focus-visible,
  > input[type="number"]:focus-visible {
    border: solid 0.1rem #4bf2cc;
    outline: 0;
  }
  > div {
    display: flex;
    width: 85%;

    > input[type="radio"] {
      display: none;
    }
    > input[type="radio"]:checked + label {
      border: solid 0.1rem #4bf2cc;
      color: #4bf2cc;
    }
    > label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: solid 0.1rem #969696;
      background-color: #747474;
      color: #fefefe;
      cursor: pointer;
    }
    > .male {
      border-radius: 0.5rem 0 0 0.5rem;
    }
    > .female {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }
`;

const DicomUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 1rem;

  > h1 {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const InputModal = ({ open, setIsModalOpen, setIsUpload }) => {
  const userName = useSelector((state) => state.UserDetailsReducer.userName);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [patientName, setPatientName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [patientID, setPatientID] = useState("");
  const [section, setSection] = useState("");
  const [seriesInstanceUID, setSeriesInstanceUID] = useState("");
  const [studyInstanceUID, setStudyInstanceUID] = useState("");
  const [seriesDate, setSeriesDate] = useState("");
  const [studyDate, setStudyDate] = useState("");
  const [studyDescription, setStudyDescription] = useState("");

  // 저장 버튼 활성화 상태
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(true);
  const firstFileData = useRef(null);

  const [resetUpload, setResetUpload] = useState(false);

  const resetValues = () => {
    firstFileData.current = null;
    setPatientName("");
    setAge("");
    setGender("");
    setPatientID("");
    setSection("");
    setSeriesInstanceUID("");
    setStudyInstanceUID("");
    setSeriesDate("");
    setStudyDate("");
    setStudyDescription("");
    setSaveButtonEnabled(true);
    setFileList([]);

    setResetUpload((prev) => !prev);
  };

  useEffect(() => {
    // fileList가 변경될 때마다 실행
    firstFileData.current = null;
    setPatientName("");
    setAge("");
    setGender("");
    setPatientID("");
    setSection("");
    setSeriesInstanceUID("");
    setStudyInstanceUID("");
    setSeriesDate("");
    setStudyDate("");
    setStudyDescription("");
    setSaveButtonEnabled(true);
  }, [fileList]);

  const extractInfoFromFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer);
        const dataSet = dicomParser.parseDicom(byteArray);

        let patientName = dataSet.string("x00100010");
        let age = dataSet.string("x00101010");
        let gender = dataSet.string("x00100040");
        let patientID = dataSet.string("x00100020");
        let seriesInstanceUID = dataSet.string("x0020000e");
        let studyInstanceUID = dataSet.string("x0020000d");
        let seriesDate = dataSet.string("x00080021");
        let studyDate = dataSet.string("x00080020");
        let studyDescription = dataSet.string("x00081030");

        let modality = dataSet.string("x00080060");
        let bodyPartExamined = dataSet.string("x00180015");

        let section = modality + "-" + bodyPartExamined;

        // 성별이 M 또는 F가 아니면 빈 문자열로 설정
        if (gender !== "M" && gender !== "F") {
          gender = "";
        }

        // 나이에서 숫자가 아닌 모든 문자를 제거
        age = age.replace(/[^\d]/g, "");

        // 맨 앞의 0을 제거
        while (age.startsWith("0")) {
          age = age.slice(1);
        }

        // 나이가 빈 문자열이면 빈 문자열로 설정
        if (age === "") {
          age = "";
        }

        resolve({
          patientName,
          age,
          gender,
          patientID,
          section,
          seriesInstanceUID,
          studyInstanceUID,
          seriesDate,
          studyDate,
          studyDescription,
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const handleCancel = () => {
    console.log("닫는다");
    resetValues();
    setIsModalOpen(false);
  };

  const handleUpload = async (file) => {
    const {
      patientName,
      age,
      gender,
      patientID,
      section,
      seriesInstanceUID,
      studyInstanceUID,
      seriesDate,
      studyDate,
      studyDescription,
    } = await extractInfoFromFile(file);

    if (!firstFileData.current) {
      firstFileData.current = {
        patientName,
        age,
        gender,
        patientID,
        section,
        seriesInstanceUID,
        studyInstanceUID,
        seriesDate,
        studyDate,
        studyDescription,
      };
    } else {
      if (
        patientName !== firstFileData.current.patientName ||
        age !== firstFileData.current.age ||
        gender !== firstFileData.current.gender ||
        patientID !== firstFileData.current.patientID ||
        section !== firstFileData.current.section ||
        seriesInstanceUID !== firstFileData.current.seriesInstanceUID ||
        studyInstanceUID !== firstFileData.current.studyInstanceUID ||
        seriesDate !== firstFileData.current.seriesDate ||
        studyDate !== firstFileData.current.studyDate ||
        studyDescription !== firstFileData.current.studyDescription
      ) {
        message.destroy();
        message.error("uploaded files have different information.");
        setSaveButtonEnabled(false);
      }
    }

    setPatientName(patientName);
    setAge(age);
    setGender(gender);
    setPatientID(patientID);
    setSection(section);
    setSeriesInstanceUID(seriesInstanceUID);
    setStudyInstanceUID(studyInstanceUID);
    setSeriesDate(seriesDate);
    setStudyDate(studyDate);
    setStudyDescription(studyDescription);
  };

  const handleOk = async () => {
    if (patientName === "" || age === "" || gender === "") {
      message.destroy();
      message.error("이름, 성별, 나이를 입력해주세요.");
      return;
    } else if (seriesInstanceUID === "" || studyInstanceUID === "") {
      message.destroy();
      message.error("uploaded files have different information.");
      return;
    }

    //const orthancServerUrl = "http://127.0.0.1:8042/instances";
    const orthancServerUrl = `http://${REACT_APP_ORTHANC_URL2}:8042/instances`;

    const user_id = userName;
    setConfirmLoading(true);

    try {
      const params = {
        patientName,
        age,
        gender,
        patientID,
        section,
        seriesInstanceUID,
        studyInstanceUID,
        seriesDate,
        studyDate,
        studyDescription,
        user_id,
      };

      const insertRes = await InsertPatient(params);

      console.log("insertRes!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", insertRes);

      // Orthanc 서버에 파일 업로드
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("file", file);
      });

      await fetch(orthancServerUrl, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
        mode: "no-cors",
      });

      const connectParams = {
        user_seq: insertRes.user_seq,
        patient_seq: insertRes.patient_seq,
        StudyInstanceUID: insertRes.studyInstanceUID,
        SeriesInstanceUID: insertRes.seriesInstanceUID,
      };

      if (insertRes.section === "CT-CHEST") {
        Patients_ConnectAI(connectParams);
      }

      setIsModalOpen(false);

      message.destroy();
      message.success("업로드 완료");

      resetValues();
      setIsUpload(true);
    } catch (error) {
      message.destroy();
      message.error("파일 업로드에 실패했습니다.");
      console.log(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <PatientModal
        title="Patient information"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <PatientModalContent>
          <InputContainer>
            <InputBox>
              <p>Name</p>
              <input
                type="text"
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
                placeholder="Name"
              />
            </InputBox>
            <InputBox>
              <p>Sex</p>
              <div>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="M"
                  checked={gender === "M"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label name="gender" htmlFor="male" className="male">
                  Male
                </label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="F"
                  checked={gender === "F"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label name="gender" htmlFor="female" className="female">
                  Female
                </label>
              </div>
            </InputBox>
            <InputBox>
              <p>Age</p>
              <input
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                placeholder="Age"
              />
            </InputBox>
          </InputContainer>
          <DicomUploadContainer>
            <h1>Dicom file</h1>
            <FileUpload
              setFileList={setFileList}
              onUpload={handleUpload}
              reset={resetUpload}
            />
          </DicomUploadContainer>
          <div>
            <SaveBtn
              onClick={handleOk}
              loading={confirmLoading}
              disabled={!isSaveButtonEnabled}
            >
              Save
            </SaveBtn>
          </div>
        </PatientModalContent>
      </PatientModal>
    </>
  );
};

export default InputModal;
