import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import styled from "styled-components";

const Container = styled.div`
  width: 99%;
  height: 99%;
  border: 1px
    ${(props) =>
      props.$isactive === "true"
        ? "dashed #4bf2cc"
        : props.$isactiveport === "true"
        ? "solid #4bf2cc"
        : "solid #2a2a2c"};
  transition: border 0.3s;
`;
const Column = styled.div`
  width: 100%;
  height: 100%;
`;
const DicomPorts = (props) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.DICOM,
      drop: () => ({ renderEngineId: props.renderEngineId, id: props.id }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  const isactive = canDrop && isOver;

  const handlePortActive = (props) => {
    props.handlePortActive(props.id);
  };

  return (
    <Container
      key={props.id}
      ref={drop}
      $isactive={isactive.toString()}
      $isactiveport={props.isactiveport.toString()}
    >
      <Column
        id={props.id}
        onMouseDown={() => handlePortActive(props)}
        onWheel={() => handlePortActive(props)}
      />
    </Container>
  );
};
export default DicomPorts;
