import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import CryptoJS from "crypto-js";
import Background from "../assets/background2.png";
import { message, Checkbox, Tooltip } from "antd";
import store from "../store/store";
import TextInput from "../components/textInput";
import PasswordInput from "../components/passwordInput";
import Lang from "../components/Lang";
import { GlobalOutlined } from "@ant-design/icons";
import { UserDetailsAction } from "../store/action";
import LucaLogo from "../components/LucaLogo";
import { UserLogin } from "../api/userApi";

const Main = styled.main`
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 1240px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(${Background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const Container = styled.div`
  padding: 85px;
  margin: 0;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InnerContainer = styled.div`
  padding: 50px;
  margin: 0;
  width: 484px;
  height: auto;
  border-radius: 24px;
  background-color: #2c2d30ed;
  box-shadow: 0 11px 34px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;
const LangContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const LogoContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
const Title = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Pretendard";
  font-size: 26px;
  font-weight: 400;
`;
const FormContainer = styled.div`
  padding: 50px;
  margin: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const InputGroup = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
`;
const Label = styled.label`
  padding: 5px;
  color: #fff;
  font-family: "Pretendard";
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
`;
const InputGroupActions = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  justify-content: space-between;
  align-items: center;
`;
const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: #4b4b51;
    border-color: #8f8f8f;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4b4b51;
    border-color: #8f8f8f;
  }
  .ant-checkbox + span {
    color: #fff;
    font-family: "Pretendard";
    font-size: 13px;
    font-weight: 400;
  }
`;
const ForgotPassword = styled.div`
  color: #fff;
  font-family: "Pretendard";
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
`;
const LoginContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
`;
const LoginButton = styled.div`
  padding: 10px;
  margin: 0;
  width: 100%;
  height: 24;
  border-radius: 22px;
  background-color: #4bf2cc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #525259;
  font-family: "Pretendard";
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
`;
const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    if (username | (password === "")) {
      message.destroy();
      message.error(t("error_login_validation"));
      return;
    }

    // 비밀번호를 SHA-256으로 해시
    const hashedPassword = CryptoJS.SHA256(password).toString();

    const params = {
      id: username,
      password: hashedPassword,
    };

    UserLogin(params)
      .then((data) => {
        const { token } = data;
        store.dispatch(UserDetailsAction(token, username));
        var luca_userDetails = { token: token, userName: username };
        localStorage.setItem(
          "luca_userDetails",
          JSON.stringify(luca_userDetails)
        );
        navigate("/PatientList");
      })
      .catch((err) => {
        if (err.message === "401") {
          message.error(t("로그인을 실패하였습니다."));
        } else {
          message.error(t("로그인을 실패하였습니다."));
        }
      });
    // store.dispatch(UserDetailsAction("token", username));
    // var userDetails = { token: "token", userName: username };
    // localStorage.setItem("luca_userDetails", JSON.stringify(userDetails));
  };

  return (
    <React.Fragment>
      <Main>
        <Container>
          <InnerContainer>
            <LangContainer>
              <GlobalOutlined
                style={{
                  color: "#8f8f8f",
                  fontSize: "22px",
                  padding: "0 10px",
                }}
              />
              <Lang />
            </LangContainer>
            <LogoContainer>
              <LucaLogo />
            </LogoContainer>
            <Title>{t("login")}</Title>
            <FormContainer>
              <InputGroup>
                <Label>{t("id")}</Label>
                <div>
                  <TextInput border={true} onChange={handleUsernameChange} />
                </div>
              </InputGroup>
              <InputGroup>
                <Label>{t("password")}</Label>
                <div>
                  <PasswordInput onChange={handlePasswordChange} />
                </div>
              </InputGroup>
              <InputGroupActions>
                <div></div>
                <Tooltip title={<Trans>{t("search_password")}</Trans>}>
                  <ForgotPassword>{t("forgot_password")}</ForgotPassword>
                </Tooltip>
              </InputGroupActions>
              <LoginContainer>
                <LoginButton onClick={handleSubmit}>{t("login")}</LoginButton>
              </LoginContainer>
            </FormContainer>
          </InnerContainer>
        </Container>
      </Main>
    </React.Fragment>
  );
};

export default Login;
