import styled, {css} from 'styled-components';

const PseudoInputContainer = styled.div`
    margin:0;
    padding:0;
    width: 100%;    
    display: flex;
    border-radius: 10px;
    background-color: #4b4b51;
    align-items: center;
    ${props => props.border &&
        css`
        border: 1px solid #8f8f8f;
    `}

`
const PseudoInput = styled.input`
    width: 100%;
    height: 23px;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: #4b4b51;
    color: #FFF;
    font-size: 17px;
    line-height: 1.23536;
    font-weight: 400;
`

const TextInput = (props) =>{
    return (
        <PseudoInputContainer border={props.border}>
            <PseudoInput type={props.type === 'password'? 'password' : 'text'} placeholder={props.placeholder} onChange={props.onChange} value={props.value} onkeypress={props.onkeypress}/>
        </PseudoInputContainer>
    )
}
export default TextInput;