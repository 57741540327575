export const ApiLoadingStateAction = (loading) => {
  return {
    type: "API_LOADING_STATE",
    loading: loading,
  };
};

export const UserDetailsAction = (token, userName) => {
  return {
    type: "USER_DETAILS",
    token: token,
    userName: userName,
  };
};

export const SideBarAction = (sideBar) => {
  return {
    type: "SIDEBAR",
    sideBar: sideBar,
  };
};

export const DtoolAction = (previouisDtool, currentDtool) => {
  return {
    type: "DTOOL",
    previouisDtool: previouisDtool,
    currentDtool: currentDtool,
  };
};

export const GridAction = (rows, cols) => {
  return {
    type: "GRID",
    rows: rows,
    cols: cols,
  };
};

export const SearchParamsAction = (
  s_patientID,
  s_date,
  s_status,
  s_modality,
  s_section
) => {
  return {
    type: "SEARCH_PARAMS",
    s_patientID: s_patientID,
    s_date: s_date,
    s_status: s_status,
    s_modality: s_modality,
    s_section: s_section,
  };
};

export const SummaryDataAction = (threshold) => {
  return {
    type: "SUMMARY_DATA",
    threshold: threshold,
  };
};

export const addPromise = (promise) => ({
  type: "ADD_PROMISE",
  payload: promise,
});

export const removePromise = (promise) => ({
  type: "REMOVE_PROMISE",
  payload: promise,
});

export const PathDetailsAction = (user_seq_store, patient_seq_store) => {
  return {
    type: "PATH_DETAILS",
    user_seq_store: user_seq_store,
    patient_seq_store: patient_seq_store,
  };
};
