import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DatePicker, ConfigProvider } from "antd";
import { SearchParamsAction } from "../store/action";
import dayjs from "dayjs";

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 0.5rem;

  > h1 {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }
  &. {
    margin: 0;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    user-select: none;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 13rem;
  justify-content: space-between;
  align-items: center;
  //padding: 0.7rem 0.4rem 0.7rem 0.8rem;
  border-radius: 0.5rem;
  background-color: #4b4b51;
`;

const TextContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
`;

const { RangePicker } = DatePicker;
const CustomDatePicker = styled(RangePicker)`
  padding: 0.7rem 0.5rem;
  cursor: pointer;

  .ant-picker-input > input {
    background-color: #4b4b51;
    color: #fff;
  }
  .ant-picker-suffix {
    color: #fff;
  }
  .ant-picker-clear {
    color: #fff;
  }
  .ant-picker-separator {
    color: #fff;
  }
  .ant-picker {
    background-color: #4b4b51;
    color: #fff;
  }
  .ant-picker-active-bar {
    background: #46d7b6;
  }
`;

const SearchDate = ({ isFiltersCleared }) => {
  const dispatch = useDispatch();
  const currentSearchParams = useSelector((state) => state.SearchParamsReducer);
  const [dates, setDates] = useState(null);

  useEffect(() => {
    if (isFiltersCleared) {
      setDates(null); // 필터가 초기화되면 날짜 선택기도 초기화
    }
  }, [isFiltersCleared]);

  const onRangeChange = (dates, dateStrings) => {
    setDates(dates);
    if (dates) {
      console.log(dateStrings[0], dateStrings[1]);
      dispatch(
        SearchParamsAction(
          currentSearchParams.s_patientID,
          `${dateStrings[0]},${dateStrings[1]}`,
          currentSearchParams.s_status,
          currentSearchParams.s_modality,
          currentSearchParams.s_section
        )
      );
    } else {
      console.log("Clear");
      dispatch(
        SearchParamsAction(
          currentSearchParams.s_patientID,
          null,
          currentSearchParams.s_status,
          currentSearchParams.s_modality,
          currentSearchParams.s_section
        )
      );
    }
  };

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 1 Month",
      value: [dayjs().add(-1, "month"), dayjs()],
    },
    {
      label: "Last 3 Months",
      value: [dayjs().add(-3, "month"), dayjs()],
    },
    {
      label: "Last 6 Months",
      value: [dayjs().add(-6, "month"), dayjs()],
    },
    {
      label: "Last 1 Year",
      value: [dayjs().add(-1, "year"), dayjs()],
    },
  ];

  return (
    <Container>
      <h1>Date</h1>
      <SearchContainer>
        <ModalContainer>
          <div>
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    colorBgContainer: "#4b4b51",
                    colorTextPlaceholder: "#fff",
                    colorBorder: "#4b4b51",
                    activeBorderColor: "#4b4b51",
                    hoverBorderColor: "#4b4b51",
                  },
                },
              }}
            >
              <CustomDatePicker
                presets={rangePresets}
                onChange={onRangeChange}
                value={dates}
              />
            </ConfigProvider>
          </div>
        </ModalContainer>
        <TextContainer></TextContainer>
      </SearchContainer>
    </Container>
  );
};

export default SearchDate;
