import React from 'react';
import styled from 'styled-components';
import { ZoomInOutlined } from '@ant-design/icons';
import store from '../../../store/store';
import { DtoolAction } from '../../../store/action';
import { useSelector } from 'react-redux';

const Icon = styled(ZoomInOutlined)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`

const ZoomIcon = () => {
    const currentDtool = useSelector(state => state.DtoolReducer.currentDtool)

    const isActive = currentDtool === 'Zoom';
    const handleClick = () => {
        store.dispatch(DtoolAction(currentDtool, 'Zoom'));
    }
    return(
        <React.Fragment>
            <Icon onClick = {handleClick} active = {isActive.toString()}/>
        </React.Fragment>
    )
}
export default ZoomIcon;
