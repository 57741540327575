const initialState = {
  s_patientID: "",
  s_date: "",
  s_status: "",
  s_modality: "",
  s_section: "",
};

const SearchParamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_PARAMS":
      return Object.assign({}, state, {
        ...state,
        s_patientID: action.s_patientID,
        s_date: action.s_date,
        s_status: action.s_status,
        s_modality: action.s_modality,
        s_section: action.s_section,
      });
    default:
      return state;
  }
};
export default SearchParamsReducer;
