import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { SearchParamsAction } from "../store/action";
import { SearchPatient } from "../api/patientApi";

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 0.5rem;

  > h1 {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }
  &. {
    margin: 0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  width: 7rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0.4rem 0.7rem 0.8rem;
  border-radius: 0.5rem;
  background-color: #4b4b51;

  @media (max-width: 1700px) {
    width: 6rem;
  }
`;

const TextInput = styled.input`
  width: 100%;
  border: none;
  background-color: #4b4b51;
  color: #fff;
  font-family: "Pretendard";
  font-size: 1rem;
  font-weight: 400;

  &:focus-visible {
    outline: none;
  }
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
`;

const SearchIcon = styled(SearchOutlined)`
  font-size: 1.2rem;
  color: #fff;
`;

const SearchID = ({ idSearchResult, isFiltersCleared }) => {
  const [input, setInput] = useState("");
  const dispatch = useDispatch();
  const currentSearchParams = useSelector((state) => state.SearchParamsReducer);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    if (isFiltersCleared) {
      setInput(""); // 필터가 초기화되면 input 값을 초기화
    }
  }, [isFiltersCleared]);

  const handleSearchClick = async () => {
    const updatedValue = input || null;

    dispatch(
      SearchParamsAction(
        updatedValue,
        currentSearchParams.s_date,
        currentSearchParams.s_status,
        currentSearchParams.s_modality,
        currentSearchParams.s_section
      )
    );

    const updatedParams = {
      ...currentSearchParams,
      s_patientID: updatedValue,
    };

    // 업데이트된 스토어 상태를 사용하여 API 호출 파라미터를 준비합니다.
    const params = {
      PatientID: updatedParams.s_patientID,
      Modality: updatedParams.s_modality,
      BodyPartExamined: updatedParams.s_section,
    };
    console.log("Search API Params:", params);

    try {
      const response = await SearchPatient(params);
      console.log(response.data);
      idSearchResult(response.data);
    } catch (error) {
      console.error("API Call Failed:", error);
    }
  };

  return (
    <Container>
      <h1>ID</h1>
      <SearchContainer>
        <TextInput type="text" value={input} onChange={handleInputChange} />
        <SearchButton onClick={handleSearchClick}>
          <SearchIcon />
        </SearchButton>
      </SearchContainer>
    </Container>
  );
};

export default SearchID;
