import React from "react";
import styled from "styled-components";
import { Modal } from "antd";

const PatientModal = styled(Modal)`
  .ant-modal-title {
    display: flex;
    justify-content: center;
    //color: #fefefe;
    font-family: "Pretendard";
    font-size: 25px;
    font-weight: 800;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    font-family: "Pretendard";
    margin: 0;
  }

  input,
  label,
  button {
    font-family: "Pretendard";
  }
  div {
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 12px 5px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 0 15px;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;

    > p {
      font-size: 17px;
      font-weight: 500;
      text-align: center;
    }
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  font-family: "Pretendard";
  color: #fff;
  background-color: #329b83;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 25px;
  transition: 0.3s;

  &:hover {
    background-color: #2c7e6d;
  }
`;

const BasicModal = (props) => {
  const handleCancel = () => {
    props.setIsBasicModalOpen(false);
  };

  return (
    <PatientModal
      title={props.title}
      onCancel={handleCancel}
      open={props.open}
      footer={null}
      style={{ top: 300 }}
      width={550}
    >
      <Container>
        <Content>
          <div>
            {Array.isArray(props.content) ? (
              props.content.map((line, index) => <p key={index}>{line}</p>)
            ) : (
              <p>{props.content}</p>
            )}
          </div>
        </Content>
        <BtnContainer>
          <Btn onClick={handleCancel}>{props.closeBtn}</Btn>
        </BtnContainer>
      </Container>
    </PatientModal>
  );
};

export default BasicModal;
