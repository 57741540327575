import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Luca from "../assets/logo.png";

const Logo = styled.img`
  width: ${(props) => (props.width ? props.width : "260px")};
  height: ${(props) => (props.height ? props.height : "34px")};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

const LucaLogo = (props) => {
  const navigate = useNavigate();

  const mainNav = () => {
    if (props.onClick) {
      navigate(`/PatientList`);
    }
  };

  return (
    <Logo
      onClick={props.onClick ? mainNav : null}
      width={props.width}
      height={props.height}
      src={Luca}
    />
  );
};
export default LucaLogo;
