import { useState, useEffect } from "react";
import store from "./store/store";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, palatte } from "./theme";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import PatientList from "./pages/PatientList";
import Viewer from "./pages/Viewer";
import Summary from "./pages/Summary";
import { fetchCheckSession } from "./api/userApi";

const App = () => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const token = useSelector((state) => state.UserDetailsReducer.token);

  //세션 시간 체크
  useEffect(() => {
    const checkSession = async () => {
      setIsLoading(true);
      const data = await fetchCheckSession();
      if (data !== "") {
        setIsUserAuthenticated(true);
      } else {
        setIsUserAuthenticated(false);
      }
      setIsLoading(false);
    };
    checkSession();
  }, [token]);

  const ProtectedRoute = ({ children }) => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
    if (!isUserAuthenticated) {
      return <Navigate to="/" />;
    }
    return children;
  };

  const patientDetails = useSelector((state) => state.PathDetailsReducer);

  return (
    <ThemeProvider theme={palatte}>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route
            exact
            path="/PatientList"
            element={
              <ProtectedRoute>
                <PatientList />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Viewer/:user_seq/:patient_seq"
            element={
              <ProtectedRoute>
                <Viewer
                  key={`${patientDetails.user_seq_store}-${patientDetails.patient_seq_store}`}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Summary/:user_seq/:patient_seq"
            element={
              <ProtectedRoute>
                <Summary />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
