import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../../store/store";
import { UserDetailsAction } from "../../store/action";
import LucaLogo from "../../components/LucaLogo";
import userImage from "../../assets/user.png";
import LogoutPng from "../../assets/logout.png";
import { UserLogout } from "../../api/userApi";
import { InfoCircleFilled } from "@ant-design/icons";
import LucaInfoModal from "./LucaInfoModal";

const Header = styled.header`
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 1240px;
  height: auto;
  background-color: #303030;
`;

const HeaderInner = styled.div`
  margin: 0;
  padding: 0 50px;
`;

const Nav = styled.nav`
  margin: auto;
  padding: 0;
  width: 100%;
  height: 63px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SignoutContainer = styled.div`
  display: flex;
  height: 100%;
`;
const UserContainer = styled.div`
  display: flex;
  height: 100%;
  border-right: 2px solid #111;
  align-items: center;
`;
const ImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #4a4a4c;
  background-image: url(${userImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Name = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 25px;
  cursor: pointer;
  border-left: 2px solid #4a4a4c;
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
const LogoutImage = styled.img`
  width: 24px;
  height: 24px;
`;

const LucaInfoContainer = styled.div`
  display: flex;
  gap: 6px;
  cursor: pointer;

  > p {
    color: #d7d7d7;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
`;

const LucaInfoIcon = styled(InfoCircleFilled)`
  color: #d7d7d7;
  font-size: 14px;
`;

const LucaHeader = (props) => {
  const navigate = useNavigate();
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false);

  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const handleLogout = async () => {
    const params = {
      user_id: userName,
    };
    const data = await UserLogout(params);
    console.log("data", data);
    store.dispatch(UserDetailsAction(null));
    localStorage.removeItem("luca_userDetails");
    navigate(`/`);
  };

  return (
    <Header>
      <HeaderInner>
        <Nav>
          <LogoContainer>
            <LucaLogo width={"162px"} height={"20px"} onClick={true} />
            <LucaInfoContainer onClick={() => setIsRefreshModalOpen(true)}>
              <p>v 1.0.0</p>
              <LucaInfoIcon />
            </LucaInfoContainer>
            <LucaInfoModal
              open={isRefreshModalOpen}
              setIsRefreshModalOpen={setIsRefreshModalOpen}
            />
          </LogoContainer>
          <SignoutContainer>
            <UserContainer>
              <ImageContainer></ImageContainer>
              {userName && userName !== "" ? <Name>{userName}</Name> : null}
            </UserContainer>
            <IconContainer onClick={handleLogout}>
              <LogoutImage src={LogoutPng} />
            </IconContainer>
          </SignoutContainer>
        </Nav>
      </HeaderInner>
    </Header>
  );
};
export default LucaHeader;
