const initialState = {
  promises: [], // 비동기 작업을 관리할 배열
};

const AnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PROMISE":
      return {
        ...state,
        promises: [...state.promises, action.payload], // 새로운 promise 추가
      };

    case "REMOVE_PROMISE":
      return {
        ...state,
        promises: state.promises.filter(
          (promise) => promise !== action.payload
        ), // 완료된 promise 제거
      };

    default:
      return state;
  }
};

export default AnalysisReducer;
