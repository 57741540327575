import React from 'react';
import styled from 'styled-components';
import { PiRectangleBold, PiCircleBold } from "react-icons/pi";
import { TbOvalVertical } from "react-icons/tb";
import { useSelector } from 'react-redux';


const RectangleIcon = styled(PiRectangleBold)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:35px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const CircleIcon = styled(PiCircleBold)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:35px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const EllipticalIcon = styled(TbOvalVertical)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:35px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`

const MainRectangleRoiIcon = () => {
    const currentDtool = useSelector(state => state.DtoolReducer.currentDtool)
    const isRectangleActive = currentDtool === 'RectangleROI';
    const isCircleActive = currentDtool === 'CircleROI';
    const isEllipticalActive = currentDtool === 'EllipticalROI';

    return(
        <React.Fragment>
            {currentDtool === 'EllipticalROI'? <EllipticalIcon active = {isEllipticalActive.toString()}/>:currentDtool === 'CircleROI'? <CircleIcon active = {isCircleActive.toString()}/>:<RectangleIcon active = {isRectangleActive.toString()}/>}
        </React.Fragment>
    )
}
export default MainRectangleRoiIcon;
