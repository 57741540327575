const initialState = {
    loading: false,
}

const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'API_LOADING_STATE':
            return Object.assign( {}, state,{
                ...state,
                loading: action.loading,
            });
        default:
            return state;
    }
}
export default SettingsReducer;