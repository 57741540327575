const initialState = {
    rows: 1,
    cols: 1,
}

const GridReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GRID':
            return Object.assign( {}, state,{
                ...state,
                rows: action.rows,
                cols: action.cols,
            });
        default:
            return state;
    }
}
export default GridReducer;