import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React, { useState } from "react";

const Select = styled.select`
    background:#4b4b51;
    border: 1px solid #8f8f8f;
    border-radius:4px;
    padding: 2px 4px;
    color: #fff;
    font-family: 'Pretendard';
    font-size: 13px;
    font-weight: 400;
    outline: none;
`
const Lang = () => {
    const { i18n } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(i18n.language); 
    
    // useEffect(() => {
    //     setSelectedOption(i18n.language)
    // }, []);
    
    const changeLanguage = (e) => {
        let language = e.target.value
        setSelectedOption(language)
        i18n.changeLanguage(language);
        let luca_theme = {"lang":language};
        localStorage.setItem("luca_theme", JSON.stringify(luca_theme)); 
    };
    
    return (
        <Select onChange={changeLanguage} value={selectedOption}>
            <option value={"kr"}>korean</option>
            <option value={"en"}>English</option>
        </Select>
    )
}
export default Lang;