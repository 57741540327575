const initialState = {
    previouisDtool: null,
    currentDtool: 'WindowLevel',
}

const DtoolReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DTOOL':
            return Object.assign( {}, state,{
                ...state,
                previouisDtool: action.previouisDtool,
                currentDtool: action.currentDtool,
            });
        default:
            return state;
    }
}
export default DtoolReducer;