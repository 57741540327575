const initialState = {
    sideBar: false,
}
const SideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SIDEBAR':
            return Object.assign( {}, state,{
                sideBar: action.sideBar,
            });
        default:
            return state;
    }
}
export default SideBarReducer;