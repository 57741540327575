// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import krTranslation from './locales/kr/translation.json';

const resources = {
  en: { translation: enTranslation },
  kr: { translation: krTranslation }
};
var luca_theme_details = JSON.parse(localStorage.getItem("luca_theme"));

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: luca_theme_details ? luca_theme_details.lang: 'kr',
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false // React already escapes HTML
    }
  });

export default i18n;
