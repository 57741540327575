import AxiosConfig from "./axiosConfig";

const axiosInstance = AxiosConfig();

export const GetPatientList = async (params) => {
  try {
    const response = await axiosInstance.post("patientList", params);
    console.log("response", response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      //Logout();
    }
    if (error.response && error.response.status === 500) {
      // window.location.href = "/luca";
      throw new Error("An error occurred.");
    }
  }
};

export const SearchPatient = async (params) => {
  try {
    const response = await axiosInstance.post("searchPatient", params);
    console.log("response", response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      //Logout();
    }
    if (error.response && error.response.status === 500) {
      // window.location.href = "/luca";
      throw new Error("An error occurred.");
    }
  }
};

export const ViewerResult = async (params) => {
  try {
    const response = await axiosInstance.post("viewerResult", params);
    //console.log("response", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      //Logout();
    }
    if (error.response && error.response.status === 500) {
      // window.location.href = "/luca";
      throw new Error("An error occurred.");
    }
  }
};
