import React from 'react';
import styled from 'styled-components';
import { PiTagBold } from "react-icons/pi";

const Icon = styled(PiTagBold)`
    color: #fff;
    font-size:35px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const TagIcon = () => {
    return(
        <React.Fragment>
                <Icon/>
        </React.Fragment>
    )
}
export default TagIcon;
