const initialState = {
  user_seq_store: "",
  patient_seq_store: "",
};

const PathDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PATH_DETAILS":
      return Object.assign({}, state, {
        ...state,
        user_seq_store: action.user_seq_store,
        patient_seq_store: action.patient_seq_store,
      });
    default:
      return state;
  }
};
export default PathDetailsReducer;
