import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

const { REACT_APP_ORTHANC_URL } = process.env;

const Container = styled.div`
  padding: 10px;
  margin: 5px 0;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const DicomImagePrev = (props) => {
  //preview image를 가져오는 함수
  //preview image 가져오는 함수 검색하려면 preview image 검색하면 됨
  const [imageSrc, setImageSrc] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDicomImage = async () => {
      try {
        setError(null);

        //const ORTHANC_BASE_URL = "http://localhost/orthancImg";
        const ORTHANC_BASE_URL = `http://${REACT_APP_ORTHANC_URL}/orthancImg`;

        const allStudiesResponse = await axios.get(
          `${ORTHANC_BASE_URL}/studies`
        );
        const allStudies = allStudiesResponse.data;

        let studyUUID = null;
        for (const study of allStudies) {
          const studyDetailsResponse = await axios.get(
            `${ORTHANC_BASE_URL}/studies/${study}`
          );
          const studyDetails = studyDetailsResponse.data;
          if (
            studyDetails.MainDicomTags.StudyInstanceUID ===
            props.StudyInstanceUID
          ) {
            studyUUID = study;
            break;
          }
        }

        if (!studyUUID) {
          throw new Error("StudyInstanceUID not found");
        }

        const seriesResponse = await axios.get(
          `${ORTHANC_BASE_URL}/studies/${studyUUID}/series`
        );
        const seriesList = seriesResponse.data;

        let seriesUUID = null;
        for (const series of seriesList) {
          const seriesDetailsResponse = await axios.get(
            `${ORTHANC_BASE_URL}/series/${series.ID}`
          );
          const seriesDetails = seriesDetailsResponse.data;
          if (
            seriesDetails.MainDicomTags.SeriesInstanceUID ===
            props.SeriesInstanceUID
          ) {
            seriesUUID = series.ID;
            break;
          }
        }

        if (!seriesUUID) {
          throw new Error("SeriesInstanceUID not found");
        }

        const instancesResponse = await axios.get(
          `${ORTHANC_BASE_URL}/series/${seriesUUID}/instances`
        );
        const instancesList = instancesResponse.data;

        const targetInstance = instancesList.find(
          //instance가 20번째인지 확인
          (instance) => instance.MainDicomTags.InstanceNumber === "35"
        );

        if (!targetInstance) {
          throw new Error("Instance number 10 not found");
        }

        const pngResponse = await axios.get(
          `${ORTHANC_BASE_URL}/instances/${targetInstance.ID}/preview`,
          {
            responseType: "blob",
          }
        );

        setImageSrc(URL.createObjectURL(pngResponse.data));
      } catch (error) {
        console.error("Error fetching DICOM image:", error);
        if (error.response) {
          // 서버가 응답을 반환했지만 2xx 범위의 상태 코드는 아닌 경우
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          // 요청이 만들어졌지만 응답을 받지 못한 경우
          console.error("Request data:", error.request);
        } else {
          // 요청을 설정하는 중에 오류가 발생한 경우
          console.error("Error message:", error.message);
        }
        setError(error.message);
      }
    };

    if (props.StudyInstanceUID && props.SeriesInstanceUID) {
      fetchDicomImage();
    } else {
      console.log("StudyInstanceUID or SeriesInstanceUID is missing");
    }
  }, [props.StudyInstanceUID, props.SeriesInstanceUID]);

  return (
    <Container>
      <LeftContainer>
        {error && <div style={{ color: "red" }}>Error: {error}</div>}
        {imageSrc && (
          <img
            src={imageSrc}
            alt="DICOM"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </LeftContainer>
    </Container>
  );
};

export default DicomImagePrev;
