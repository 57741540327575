import React from 'react';
import styled from 'styled-components';
import { ColumnWidthOutlined, ExpandAltOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const LengthIcon = styled(ColumnWidthOutlined)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
    transform: rotate(-45deg);
`
const BiDirectionalIcon = styled(ExpandAltOutlined)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const MainLineIcon = () => {
    const currentDtool = useSelector(state => state.DtoolReducer.currentDtool)
    const isBidirectionalActive = currentDtool === 'Bidirectional';
    const isLengthActive = currentDtool === 'Length';
    return(
        <React.Fragment>
            {currentDtool === 'Bidirectional'? <BiDirectionalIcon active = {isBidirectionalActive.toString()}/>:<LengthIcon active = {isLengthActive.toString()}/>}
        </React.Fragment>
    )
}
export default MainLineIcon;
