import { configureStore } from "@reduxjs/toolkit";
import SettingsReducer from "./reducer/Settings";
import UserDetailsReducer from "./reducer/UserDetails";
import SideBarReducer from "./reducer/SideBar";
import DtoolReducer from "./reducer/Dtool";
import GridReducer from "./reducer/Grid";
import SearchParamsReducer from "./reducer/SearchParams";
import SummaryDataReducer from "./reducer/SummaryData";
import AnalysisReducer from "./reducer/Analysis";
import PathDetailsReducer from "./reducer/PathDetails";

const store = configureStore(
  {
    reducer: {
      SettingsReducer: SettingsReducer,
      UserDetailsReducer: UserDetailsReducer,
      SideBarReducer: SideBarReducer,
      DtoolReducer: DtoolReducer,
      GridReducer: GridReducer,
      SearchParamsReducer: SearchParamsReducer,
      SummaryDataReducer: SummaryDataReducer,
      AnalysisReducer: AnalysisReducer,
      PathDetailsReducer: PathDetailsReducer,
    },
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
