import React from "react";
import styled from "styled-components";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const Left = styled.div`
  display: flex;
`;
const Right = styled.div`
  display: flex;
  font-family: "Pretendard";
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const DoughnutChart = (props) => {
  const settings = {
    width: 130,
    height: 130,
    value: 60,
    startAngle: 0,
    endAngle: 360,
    innerRadius: "80%",
    outerRadius: "100%",
    text: "df",
  };
  return (
    <React.Fragment>
      <Container>
        <Left>
          <Gauge
            //   {...settings}
            value={props.risk}
            startAngle={0}
            endAngle={360}
            innerRadius="80%"
            outerRadius="100%"
            width={130}
            height={130}
            text={({ value }) => `${value} %`}
            sx={(theme) => ({
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 20,
              },
              [`& .${gaugeClasses.valueText} > text`]: {
                fill: "#fff",
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: props.risk >= props.threshold ? "#F53E3E" : "#4bb8f2",
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: props.risk >= props.threshold ? "#822e2e" : "#2c6685",
              },
            })}
          />
        </Left>
        <Right
          risk={props.risk >= props.threshold ? "High" : "low"}
          style={{
            color: props.risk >= props.threshold ? "#F53E3E" : "#4bb8f2",
          }}
        >
          {props.risk >= props.threshold ? "High risk" : "Low risk"}
        </Right>
      </Container>
    </React.Fragment>
  );
};
export default DoughnutChart;
