import React, { useState } from 'react';
import styled from 'styled-components';
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';

const PseudoInputContainer = styled.div`
    margin:0;
    padding:0;
    width: 100%;    
    display: flex;
    border: 1px solid #8f8f8f;
    border-radius: 10px;
    background-color: #4b4b51;
`
const PseudoInput = styled.input`
    width: 100%;
    height: 23px;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: #4b4b51;
    color: #FFF;
    font-size: 17px;
    line-height: 1.23536;
    font-weight: 400;
`
const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 15px;
    cursor: pointer;
`
const PasswordInput = (props) =>{

    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordIcon = () => {
        setShowPassword(!showPassword);
    }

    return (
        <PseudoInputContainer>
            <PseudoInput type={showPassword? "text":"password"} placeholder={props.placeholder} onChange={props.onChange}/>
            <IconContainer onClick={handlePasswordIcon}>
                {showPassword? <EyeOutlined style={{ color: '#8f8f8f', fontSize: '24px' }}/> : <EyeInvisibleOutlined style={{ color: '#8f8f8f', fontSize: '24px' }}/>}
            </IconContainer>
        </PseudoInputContainer>
    )
}
export default PasswordInput;