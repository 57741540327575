import React from 'react';
import styled from 'styled-components';
import { RiDeleteBin7Line } from "react-icons/ri";


const Icon = styled(RiDeleteBin7Line)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const DeleteIcon = (props) => {
    const handleDelete = (props) => {
        props.handleDelete();
    };
    
    return(
        <React.Fragment>
            <Icon onClick={() => handleDelete(props)}/>
        </React.Fragment>
    )
}
export default DeleteIcon;