import React, {useState} from 'react';
import styled from 'styled-components';
import { TableOutlined } from '@ant-design/icons';
import store from '../../../store/store';
import { GridAction } from '../../../store/action';

const Icon = styled(TableOutlined)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const InvisibleContainer = styled.div`
    margin: 0;
    padding:0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width:100%;
    height:100%;
    position: fixed;
    z-index:100;
    display: ${({ $isvisible }) => ($isvisible === 'true' ? 'flex' : 'none')};
`

const Cell = styled.div`
    margin: 0;
    padding:0;
    width: 80%;
    height: 80%;
    background-color: ${props => props.$highlighted === 'true' ? '#4bf2cc' : '#fff'};
    transition: background-color 0.3s;
`


const NearbyDiv = styled.div`
    position: absolute;
    top: 130px;
    left: 175px;
    padding: 5px 0;
    margin: 0;
    width: 150px;
    height: 150px;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1;
    display: ${({ $isvisible }) => ($isvisible ==='true' ? 'flex' : 'none')};
    background-color:#363636;
`
const Row = styled.div`
    padding: 0;
    margin: 0;
    width: 90%;
    height: 45%;
    display: flex;
`
const CellOuter = styled.div`
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`


const Layout = () => {
    const numberOfRows = 4;
    const numberOfColumns = 4;

    const [isNearbyDivVisible, setIsNearbyDivVisible] = useState(false);
    const [isActive, setisActive] = useState(false);
    const [hoveredCell, setHoveredCell] = useState({ row: -1, col: -1 });

    const handleClick = () => {
        setIsNearbyDivVisible(!isNearbyDivVisible);
        setisActive(!isActive);
    };
    const handleMouseEnter = (rowIndex, colIndex) => {
        setHoveredCell({ row: rowIndex, col: colIndex });
    };
    
    const handleCellClick = (rowIndex, colIndex) => {
        const newSize = {
            rows: rowIndex + 1,
            cols: colIndex + 1,
        };
        store.dispatch(GridAction(newSize.rows, newSize.cols));
    };
    return(
        <React.Fragment>
            <Icon onClick = {handleClick} active = {isActive.toString()}/>
            <InvisibleContainer onClick = {handleClick} $isvisible={isNearbyDivVisible.toString()}>
                <NearbyDiv $isvisible={isNearbyDivVisible.toString()}>
                    {[...Array(numberOfRows)].map((_, rowIndex) => (
                        <Row key={rowIndex}>
                            {[...Array(numberOfColumns)].map((_, colIndex) => (
                                <CellOuter key={colIndex}>
                                    <Cell 
                                        $highlighted={String(rowIndex <= hoveredCell.row && colIndex <= hoveredCell.col)}
                                        onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                                        onMouseLeave={() => handleMouseEnter(-1, -1)}
                                        onClick={() => handleCellClick(rowIndex, colIndex)}
                                    />
                                </CellOuter>
                            ))}
                        </Row>
                    ))}
                </NearbyDiv>
            </InvisibleContainer>
        </React.Fragment>
    )
}
export default Layout;

