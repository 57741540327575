import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const RadioButton = styled.input`
  display: none;

  &:checked + span .radio-dot {
    background-color: #fff;
  }

  &:checked + span .radio-label {
    font-weight: 400;
  }
`;

const RadioLabel = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 3px;

  .radio-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    box-sizing: border-box;
  }

  .radio-dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .radio-label {
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
`;

const SummaryLang = ({ onChangeLanguage }) => {
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(i18n.language);

  const changeLanguage = (e) => {
    let language = e.target.value;
    setSelectedOption(language);
    i18n.changeLanguage(language);
    let luca_theme = { lang: language };
    localStorage.setItem("luca_theme", JSON.stringify(luca_theme));
    onChangeLanguage(language);
  };

  return (
    <RadioWrapper>
      <label>
        <RadioButton
          type="radio"
          value="kr"
          checked={selectedOption === "kr"}
          onChange={changeLanguage}
        />
        <RadioLabel>
          <span className="radio-circle">
            <span className="radio-dot" />
          </span>
          <span className="radio-label">Korean</span>
        </RadioLabel>
      </label>
      <label>
        <RadioButton
          type="radio"
          value="en"
          checked={selectedOption === "en"}
          onChange={changeLanguage}
        />
        <RadioLabel>
          <span className="radio-circle">
            <span className="radio-dot" />
          </span>
          <span className="radio-label">English</span>
        </RadioLabel>
      </label>
    </RadioWrapper>
  );
};

export default SummaryLang;
