const initialState = {
  threshold: "",
};

const SummaryDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SUMMARY_DATA":
      return Object.assign({}, state, {
        ...state,
        threshold: action.threshold,
      });
    default:
      return state;
  }
};

export default SummaryDataReducer;
