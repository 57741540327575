import React from 'react';
import styled from 'styled-components';
import { RotateRightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Icon = styled(RotateRightOutlined)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:30px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const MainRotateIcon = () => {
    const currentDtool = useSelector(state => state.DtoolReducer.currentDtool)
    const isActive = currentDtool === 'PlanarRotate';
    return(
        <React.Fragment>
            <Icon active = {isActive.toString()}/>
        </React.Fragment>
    )
}
export default MainRotateIcon;
