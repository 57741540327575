import React, { useState } from "react";
import styled from "styled-components";
import Slider from "@mui/material/Slider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 0;
`;
const Bottom = styled.div`
  display: flex;
  width: 90%;
`;
const Right = styled.div`
  display: flex;
  width: 100%;
  color: #fefefe;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  justify-content: center;
`;
const SliderChart = (props) => {
  const [value, setValue] = useState(props.threshold);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    props.handleSliderChange(newValue);
  };
  return (
    <React.Fragment>
      <Container>
        <Right risk={props.risk >= props.threshold ? "High" : "low"}>
          Threshold {value} %
        </Right>
        <Bottom>
          <Slider
            value={value}
            onChange={handleSliderChange}
            sx={{
              "& .MuiSlider-thumb": {
                color: props.risk >= props.threshold ? "#F53E3E" : "#4bb8f2",
              },
              "& .MuiSlider-track": {
                color: props.risk >= props.threshold ? "#F53E3E" : "#4bb8f2",
              },
              "& .MuiSlider-rail": {
                color: props.risk >= props.threshold ? "#822e2e" : "#2f896f",
              },
              "& .MuiSlider-active": {
                color: props.risk >= props.threshold ? "#822e2e" : "#2f896f",
              },
            }}
          />
        </Bottom>
      </Container>
    </React.Fragment>
  );
};
export default SliderChart;
