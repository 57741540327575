import React from 'react';
import styled from 'styled-components';
import { RxAngle } from "react-icons/rx";
import { PiAngle } from "react-icons/pi";
import { useSelector } from 'react-redux';

const AngleIcon = styled(PiAngle)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:35px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const CobbAngleIcon = styled(RxAngle)`
    color: ${props => props.active === 'true' ? '#4bf2cc' : '#fff'};
    font-size:35px;
    transition: background-color 0.3s ease;
    cursor:pointer;
    &:hover {
        color: #4bf2cc;
    }
`
const MainAngleIcon = () => {
    const currentDtool = useSelector(state => state.DtoolReducer.currentDtool)
    const isCobbAngleActive = currentDtool === 'CobbAngle';
    const isAngleActive = currentDtool === 'Angle';
    return(
        <React.Fragment>
            {currentDtool === 'CobbAngle'? <CobbAngleIcon active = {isCobbAngleActive.toString()}/>:<AngleIcon active = {isAngleActive.toString()}/>}
        </React.Fragment>
    )
}
export default MainAngleIcon;
