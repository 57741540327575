import { volumeLoader, setVolumesForViewports } from "@cornerstonejs/core";

const volumeName = "LUCAVOLUME";
const volumeLoaderScheme = "cornerstoneStreamingImageVolume";

export const loadVolume = async (
  renderingEngine,
  viewportId,
  imageIds,
  name,
  user_seq,
  patient_seq
) => {
  const vp = renderingEngine.getViewport(viewportId);

  // 캐시된 볼륨을 무시하고 새로 로드하기 위해 unique한 volumeId 생성
  const volumeId = `${volumeLoaderScheme}:${volumeName}:${name}:${user_seq}:${patient_seq}`;

  console.log("volumeId", volumeId);
  // 새롭게 볼륨을 로드하고 캐시에 저장
  const volume = await volumeLoader.createAndCacheVolume(volumeId, {
    imageIds: imageIds,
  });

  // 볼륨 로드 완료 후 설정 적용
  await volume.load(); // load()는 async 함수이므로 await로 대기

  setVolumesForViewports(
    renderingEngine,
    [{ volumeId: volumeId }],
    [viewportId]
  );

  // 초기화 후 VOI 설정 및 렌더링
  setTimeout(() => {
    vp.setProperties({
      voiRange: { lower: 0, upper: 255 },
    });
    vp.render();
  }, 0);
};

export const updateVolume = async (
  renderingEngine,
  viewportId,
  imageIds,
  name
) => {
  const vp = renderingEngine.getViewport(viewportId);
  const volumeId = `${volumeLoaderScheme}:${volumeName}:${name}`;
  const volume = await volumeLoader.createAndCacheVolume(volumeId, {
    imageIds: imageIds,
  });
  volume.load();
  setVolumesForViewports(
    renderingEngine,
    [{ volumeId: volumeId }],
    [viewportId]
  );

  // 초기화 후 설정 적용
  setTimeout(() => {
    vp.setProperties({
      voiRange: { lower: 0, upper: 255 },
    });
    vp.render();
  }, 0);
};
