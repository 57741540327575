import { createGlobalStyle } from "styled-components";

import Pretendard_Black_woff2 from "./fonts/Pretendard/woff2/Pretendard-Black.woff2";
import Pretendard_Black_woff from "./fonts/Pretendard/woff/Pretendard-Black.woff";
import Pretendard_ExtraBold_woff2 from "./fonts/Pretendard/woff2/Pretendard-ExtraBold.woff2";
import Pretendard_ExtraBold_woff from "./fonts/Pretendard/woff/Pretendard-ExtraBold.woff";
import Pretendard_Bold_woff2 from "./fonts/Pretendard/woff2/Pretendard-Bold.woff2";
import Pretendard_Bold_woff from "./fonts/Pretendard/woff/Pretendard-Bold.woff";
import Pretendard_SemiBold_woff2 from "./fonts/Pretendard/woff2/Pretendard-SemiBold.woff2";
import Pretendard_SemiBold_woff from "./fonts/Pretendard/woff/Pretendard-SemiBold.woff";
import Pretendard_Medium_woff2 from "./fonts/Pretendard/woff2/Pretendard-Medium.woff2";
import Pretendard_Medium_woff from "./fonts/Pretendard/woff/Pretendard-Medium.woff";
import Pretendard_Regular_woff2 from "./fonts/Pretendard/woff2/Pretendard-Regular.woff2";
import Pretendard_Regular_woff from "./fonts/Pretendard/woff/Pretendard-Regular.woff";
import Pretendard_Light_woff2 from "./fonts/Pretendard/woff2/Pretendard-Light.woff2";
import Pretendard_Light_woff from "./fonts/Pretendard/woff/Pretendard-Light.woff";
import Pretendard_ExtraLight_woff2 from "./fonts/Pretendard/woff2/Pretendard-ExtraLight.woff2";
import Pretendard_ExtraLight_woff from "./fonts/Pretendard/woff/Pretendard-ExtraLight.woff";
import Pretendard_Thin_woff2 from "./fonts/Pretendard/woff2/Pretendard-Thin.woff2";
import Pretendard_Thin_woff from "./fonts/Pretendard/woff/Pretendard-Thin.woff";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Pretendard';
        font-weight: 900;
        src: url(${Pretendard_Black_woff2}) format('woff2'),
        url(${Pretendard_Black_woff}) format('woff');    
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 800;
        src: url(${Pretendard_ExtraBold_woff2}) format('woff2'),
        url(${Pretendard_ExtraBold_woff}) format('woff');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 700;
        src: url(${Pretendard_Bold_woff2}) format('woff2'),
        url(${Pretendard_Bold_woff}) format('woff');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 600;
        src: url(${Pretendard_SemiBold_woff2}) format('woff2'),
        url(${Pretendard_SemiBold_woff}) format('woff');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 500;
        src: url(${Pretendard_Medium_woff2}) format('woff2'),
        url(${Pretendard_Medium_woff}) format('woff');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 400;
        src: url(${Pretendard_Regular_woff2}) format('woff2'),
        url(${Pretendard_Regular_woff}) format('woff');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 300;
        src: url(${Pretendard_Light_woff2}) format('woff2'),
        url(${Pretendard_Light_woff}) format('woff');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 200;
        src: url(${Pretendard_ExtraLight_woff2}) format('woff2'),
        url(${Pretendard_ExtraLight_woff}) format('woff');
    }

    @font-face {
        font-family: 'Pretendard';
        font-weight: 100;
        src: url(${Pretendard_Thin_woff2}) format('woff2'),
        url(${Pretendard_Thin_woff}) format('woff');
    }
   
    html {
        font-size: 16px;
    }

    @media (max-width: 1700px) {
        html {
            font-size: 15px;
        }
    }

    @media (max-width: 1600px) {
        html{
            font-size: 14px;
        }
    }
    
    @media (max-width: 1500px) {
        html{
            font-size: 13px;
        }
    }

    @media (max-width: 1400px) {
        html{
            font-size: 12.5px;
        }
    }
    
    body {
        margin: 0;
        padding:0;
        font-family: 'Pretendard' , sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-width: 1024px;
        background-color: #191919;
        overflow-x: auto;
    }

    #luca{
        padding:0;
        margin:0;
        width:100%;
        height:100%;
    }
   
    input[type="search"]{
        -webkit-appearance: textfield;
    }
    input[type="search"]::-webkit-search-cancel-button{
        -webkit-appearance: none;
        -moz-appearance:none;
    }
    .overlay {
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #141414;
        opacity: .63;
        overflow: hidden;
    }
    .overlay-enabled{
        overflow: hidden;
        // margin-right:15px;
    }
    ::-webkit-scrollbar {
        background: #000000;
        width:10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #4b4b51;
        border-radius: 5px;
    }
    .ant-popover-inner{
        background-color: #212121 !important;
        min-width:300px !important;
    }
    .item-row {
        background-color: #28282c;
    }
    .child-row {
      background-color: #28282c;
    }
`;

export const palatte = {
  primaryWhite: "#FFFFFF",
  secondaryWhite: "#fafafa",
  primaryBlack: "#000000",
  primaryGrey: "#242424",
  secondaryGrey: "#363636",
};
